import React, { useEffect, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { SpaceComponent } from "../shared/Shared";
import { OmisCheckBox, OmisDateTimePicker, OmisTextArea, OmisTextBox } from "../shared/OmisInputs";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisDropdown, OmisDropdownMultiselect } from "../shared/OmisDropdowns";
import { OmisCircularProgress } from "../shared/OmisProgressDisplays";
import { Link as RouterLink } from "react-router-dom";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function ReportEdit(props) {

    const [multiSelectSeverity, setMultiSelectSeverity] = useState(false);

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const [initialValues, setInitialValues] = useState({
        FaultReportID:0,
        Announciator_Name: '',
        AdditionalRecipients: '',
        Faultdescription: '',
        DateToCompleteAction: null,
        ImminentDanger: false,
        ClearanceDate: null,
        DateToCompleteActionCustom: null,
        SeverityID: '0',
        SeverityIDs:[],
        ReportNrExtern :''
    });

    const [severities, setSeverities] = useState([]);

    useEffect(() => {

        httpclientService.get(`api/reports/getseverities`).then((response) => {
            if (response) {
                setSeverities(response);
            }
        });

        httpclientService.get(`/api/reports/details?reportid=${props.selectedRowID}`).then((response) => {
            if (response.FaultReportID) {
                setInitialValues({
                    FaultReportID: response.FaultReportID ? response.FaultReportID : props.selectedRowID,
                    Announciator_Name: response.Announciator_Name ? response.Announciator_Name:"",
                    AdditionalRecipients: response.AdditionalRecipients ? response.AdditionalRecipients :"",
                    Faultdescription: response.FaultDescription ? response.FaultDescription : "",
                    DateToCompleteAction: response.DateToCompleteAction ? new Date(response.DateToCompleteAction) : null,
                    ImminentDanger: response.ImminentDanger,
                    ClearanceDate: response.ClearanceDate ? new Date(response.ClearanceDate) : null,
                    DateToCompleteActionCustom: response.DateToCompleteActionCustom ? new Date(response.DateToCompleteActionCustom) : null,
                    SeverityID: response.SeverityID ? response.SeverityID : '',
                    SeverityIDs: response.SeverityIDs,
                    ReportNrExtern: response.ReportNrExtern ? response.ReportNrExtern : "",
                });
            }
        });

    }, []);

    function handleSave(values) {
        if (values.SeverityID === '' || values.SeverityID === '0') {
            values.SeverityID = null;
        }
        httpclientService.post("/api/reports/savereportedit", JSON.stringify(values)).then((response) => {
            return response;
        });
        props.handleModalClose();
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    Announciator_Name: Yup.string()
                        .required(t("GenericRequiredText"))
                        .max(100, t('Error_TextTooLong', { 1: 100 })),
                    Faultdescription: Yup.string()
                        .required(t("GenericRequiredText"))
                        .max(4000, t('Error_TextTooLong', { 1: 4000 })),
                })}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>

                        <OmisContainer fluid>

                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("Annunciator")} name={"Announciator_Name"} placeholder={t("Annunciator")} required />
                                </OmisCol>
                                <OmisCol xs={8}>
                                    <OmisTextBox labeltext={t("FaultReport_AdditionalRecipients")} name={"AdditionalRecipients"} placeholder={t("FaultReport_AdditionalRecipients")} />
                                </OmisCol>
                            </OmisRow>


                            <OmisRow>
                                <OmisCol xs={12}>
                                    <OmisTextArea labeltext={t("FaultDescription")} name={"Faultdescription"} placeholder={t("FaultDescription")} required />
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={12}>
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={12}>
                                    <span>{t("FaultReport_DateToCompleteAction")}</span>
                                    <SpaceComponent />
                                    <OmisDateTimePicker disabled={true} name="DateToCompleteAction" />
                                    {/*<span>{initialValues.dateToCompleteAction}</span>*/}
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={4}>
                                    {t("FaultReport_ClearanceDate")}
                                    <OmisCol xs={12}>
                                        <OmisDateTimePicker name="ClearanceDate" />
                                    </OmisCol>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    {t("FaultReport_DateToCompleteActionCustom")}
                                    <OmisCol xs={12}>
                                        <OmisDateTimePicker name="DateToCompleteActionCustom" />
                                    </OmisCol>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCol xs={12}>
                                        <br />
                                        <OmisCheckBox labeltext={t("ImminentDanger")} name={"ImminentDanger"} />
                                    </OmisCol>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    {t("FaultReport_ReportNrExtern")}
                                    <OmisTextBox labeltext={""} name={"ReportNrExtern"} placeholder={t("FaultReport_ReportNrExtern")} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    {t("Severity")}
                                    {
                                        multiSelectSeverity ?
                                            <OmisCol xs={12}>
                                                <OmisDropdownMultiselect size={"small"} id={"severities"} name={"SeverityIDs"} labeltext={t("Severity")} items={severities} />
                                            </OmisCol> :
                                            <OmisCol xs={12}>
                                                <OmisDropdown size={"small"} id={"severities"} name={"SeverityID"} items={severities} />
                                            </OmisCol>
                                    }
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={6}>
                                    {
                                        props.handleModalCancel ?
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                                            :
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/users"}></OmisButtonSecondary>
                                    }
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid || submitButtonDisabled} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>

                        </OmisContainer>

                    </Form>
                )}
            </Formik>
        </>
    );
}


export function ChangeResponsiblePerson(props) {

    const [isLoading, setIsLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        faultReportID: props.faultReportID ? props.faultReportID :0,
        companySiteID: 0
    });

    const [companySites, setCompanySites] = useState([]);

    useEffect(() => {
        if (props.faultReportID) {
            httpclientService.get(`/api/reports/getcompanysiteswithcurrent?faultReportID=${props.faultReportID}`).then((response) => {
                if (response) {
                    setCompanySites(response.companySites);

                    setInitialValues({
                        faultReportID: props.faultReportID,
                        companySiteID: response.companySiteID
                    });

                    setIsLoading(false);
                }
            });
        } else if (props.selectedRowIDs) {
            httpclientService.get(`/api/reports/getcompanysitesbycustomer`).then((response) => {
                if (response) {
                    setCompanySites(response);
                    setIsLoading(false);
                }
            });
        }
    }, []);

    function handleSave(values) {
        if (props.faultReportID) {
            httpclientService.post(`/api/reports/changecompanysite?faultReportID=${values.faultReportID}&companySiteID=${values.companySiteID}`).then((response) => {
                return response;
            });
        } else if (props.selectedRowIDs) {
            props.selectedRowIDs.map((id) => {
                httpclientService.post(`/api/reports/changecompanysite?faultReportID=${id}&companySiteID=${values.companySiteID}`).then((response) => {
                    return response;
                });
            });
        }
        props.handleModalClose(1);
    }

    if (isLoading) {
        return (
            <>
                <OmisCircularProgress size={100} />
            </>
        );
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    companySiteID: Yup.number().required(t("GenericRequiredText")),
                })}
                onSubmit={(values) => {
                    handleSave(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisCol xs={6} >
                                    <OmisDropdown labeltext={t("CompanySite")} size={"small"} id={"companySiteID"} name={"companySiteID"} items={companySites} required />
                                </OmisCol>
                            </OmisCol>
                            <OmisCol xs={12}>
                                <OmisCol xs={6}><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit> </OmisCol>
                                <OmisCol xs={6}><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary> </OmisCol>
                            </OmisCol>
                        </OmisRow>
                    </Form>
                )}
            </Formik>
        </>
    );
}