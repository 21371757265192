import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisAutosizeTextAreaV2, OmisCheckBox, OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisLabel } from "../../shared/OmisDisplays";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../../shared/OmisLayouts";

export function CreateMessagingParameter(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [faultReportKinds, setFaultReportKinds] = useState([]);
    const [workflowStatuses, setWorkflowStatuses] = useState([]);
    const [contactChannels, setContactChannels] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        description: props.rowVals?.description ?? "",
        faultReportKindID: props.rowVals?.faultReportKindID ?? null,
        workflowStatus: props.rowVals?.workflowStatus ?? null,
        messageNr: props.rowVals?.messageNr ?? "",
        contactChannelID: props.rowVals?.contactChannelID ?? null,
        template: props.rowVals?.template ?? "",
        imminentDanger: props.rowVals?.imminentDanger ?? false,
        sendToSubReports: props.rowVals?.sendToSubReports ?? false,
        IHC: props.rowVals?.IHC ?? false,
        IHV: props.rowVals?.IHV ?? false,
        dispatcher: props.rowVals?.dispatcher ?? false,
        areaManager: props.rowVals?.areaManager ?? false,
        contractor: props.rowVals?.contractor ?? false,
        objectOwner: props.rowVals?.objectOwner ?? false,
        currentUser: props.rowVals?.currentUser ?? false,
        tenderContractor: props.rowVals?.tenderContractor ?? false,
        annunciator: props.rowVals?.annunciator ?? false,
        SAP: props.rowVals?.SAP ?? false,
        addresses: props.rowVals?.addresses ?? "",

    });

    useEffect(() => {
        httpclientService.get("/api/settings/settingsreports/getfaultreportkinds").then((response) => {
            if (response && response.length > 0) {
                setFaultReportKinds(response);
            }
        });

        httpclientService.get("/api/settings/settingsreports/getworkflowstatuses").then((response) => {
            if (response && response.length > 0) {
                setWorkflowStatuses(response);
            }
        });

        httpclientService.get("/api/settings/settingsreports/getcontactchannels").then((response) => {
            if (response && response.length > 0) {
                setContactChannels(response);
            }
        });
    }, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsreports/savemessagingparameter", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(64, t('Error_TextTooLong', { 1: 64 })),
        faultReportKindID: Yup.number()
            .required(t("GenericRequiredText"))
            .transform((value) => Number.isNaN(value) ? null : value)
            .typeError(t("GenericRequiredText"))
            .min(1, t("GenericRequiredText")),
        workflowStatus: Yup.string()
            .required(t("GenericRequiredText"))
            .transform((value) => Number.isNaN(value) ? null : value)
            .typeError(t("GenericRequiredText"))
            .test(
                'oneOfRequired',
                t("GenericRequiredText"),
                function (item) {
                    if (item === 0 || item === '0' || item === null) {
                        return false;
                    }
                    return true;
                }
            )
            .min(1, t("GenericRequiredText")),
        messageNr: Yup.number()
            .required(t("GenericRequiredText"))
            .min(0),
        contactChannelID: Yup.number()
            .required(t("GenericRequiredText"))
            .transform((value) => Number.isNaN(value) ? null : value)
            .typeError(t("GenericRequiredText"))
            .min(0, t("GenericRequiredText")),
        template: Yup.string()
            .required(t("GenericRequiredText"))
            .max(256, t('Error_TextTooLong', { 1: 256 })),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("MessagingParameter_Settings")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <Card>
                                <OmisContainer fluid>
                                    <OmisStack>
                                        <OmisRow>
                                            <OmisCol xs={6}>
                                                <OmisCol xs={12}>
                                                    <OmisTextBox labeltext={t("Description")} name="description" required />
                                                </OmisCol>
                                                <OmisRow>
                                                    <OmisCol xs={6}>
                                                        <OmisTextBox labeltext={t("MessageNr")} name="messageNr" type="number" required />
                                                    </OmisCol>
                                                    <OmisCol xs={6}>
                                                        <OmisDropdown size={"small"} id={"workflowStatus"} name={"workflowStatus"} items={workflowStatuses} labeltext={t("WorkflowStatus")} required />
                                                    </OmisCol>
                                                </OmisRow>
                                                <OmisCol xs={6}>
                                                    <OmisLabel label={t("AdditionalDetail")} />
                                                    <OmisCheckBox labeltext={t("ImminentDanger")} name="imminentDanger" />
                                                    <OmisCheckBox labeltext={t("MessagingParameter_SendToSubReports")} name="sendToSubReports" />
                                                    <br />
                                                </OmisCol>
                                            </OmisCol>
                                            <OmisCol xs={6}>
                                                <OmisDropdown size={"small"} id={"contactChannelID"} name={"contactChannelID"} items={contactChannels} labeltext={t("MessagingParameter_ContactChannel")} required />
                                                <OmisDropdown size={"small"} id={"faultReportKindID"} name={"faultReportKindID"} items={faultReportKinds} labeltext={t("FaultReportKind")} required />
                                                <OmisAutosizeTextAreaV2 labeltext={t("MessagingParameter_Template")} name="template" required />
                                            </OmisCol>
                                        </OmisRow>
                                    </OmisStack>
                                </OmisContainer>
                            </Card>

                            <strong>{t("Recipient")}</strong>
                            <Card>
                                <OmisRow>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("MessagingParameter_IHC")} name="IHC" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("MessagingParameter_IHV")} name="IHV" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("MessagingParameter_Dispatcher")} name="dispatcher" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("MessagingParameter_AreaManager")} name="areaManager" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("Contractor")} name="contractor" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("MessagingParameter_ObjectOwner")} name="objectOwner" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("MessagingParameter_CurrentUser")} name="currentUser" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisCheckBox labeltext={t("MessagingParameter_TenderContractor")} name="tenderContractor" />
                                    </OmisCol>

                                    <OmisCol xs={3}>
                                        <br />
                                        <OmisCheckBox labeltext={t("Annunciator")} name="annunciator" />
                                    </OmisCol>

                                    <OmisCol xs={3}>
                                        <br />
                                        <OmisCheckBox labeltext={"SAP"} name="SAP" />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisTextBox labeltext={t("Settings_Addresses")} name="addresses" />
                                    </OmisCol>
                                </OmisRow>
                            </Card>
                            <br />
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}